import { createSlice } from '@reduxjs/toolkit';
import GlobalTagsState from '../../types/GlobalTagsState';

const initialState: GlobalTagsState = {
    items: [],
    fetchGlobalTags: true,
    fetchGlobalTagsFailed: false,
    addGlobalTag: false,
    addGlobalTagFailed: false,
    deleteGlobalTag: false,
    deleteGlobalTagFailed: false,
    updateGlobalTag: false,
    updateGlobalTagFailed: false,
    addGlobalTagCondition: false,
    addGlobalTagConditionFailed: false,
    deleteGlobalTagCondition: false,
    deleteGlobalTagConditionFailed: false,
    updateGlobalTagCondition: false,
    updateGlobalTagConditionFailed: false,
};

const globalTagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        loadGlobalTags: state => {
            state.fetchGlobalTags = true;
        },
        loadGlobalTagsFailed: state => {
            state.fetchGlobalTags = false;
            state.fetchGlobalTagsFailed = true;
        },
        loadGlobalTagsSuccess: (state, action) => {
            state.items = action.payload;
            state.fetchGlobalTags = false;
        },
        addGlobalTag: state => {
            state.addGlobalTag = true;
        },
        addGlobalTagFailed: state => {
            state.addGlobalTag = false;
            state.addGlobalTagFailed = true;
        },
        addGlobalTagSuccess: (state, action) => {
            state.items.push(action.payload);
            state.addGlobalTag = false;
        },
        deleteGlobalTag: state => {
            state.deleteGlobalTag = true;
        },
        deleteGlobalTagFailed: state => {
            state.deleteGlobalTag = false;
            state.deleteGlobalTagFailed = true;
        },
        deleteGlobalTagSuccess: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload.id);
            state.deleteGlobalTag = false;
        },
        updateGlobalTag: state => {
            state.updateGlobalTag = true;
        },
        updateGlobalTagFailed: state => {
            state.updateGlobalTag = false;
            state.updateGlobalTagFailed = true;
        },
        updateGlobalTagSuccess: (state, action) => {
            const globalTagIndex = state.items.findIndex(item => item.id === action.payload.id)!;

            state.items[globalTagIndex] = {
                ...state.items[globalTagIndex],
                ...action.payload,
            };
            state.updateGlobalTag = false;
        },
        addGlobalTagCondition: state => {
            state.addGlobalTagCondition = true;
        },
        addGlobalTagConditionFailed: state => {
            state.addGlobalTagCondition = false;
            state.addGlobalTagConditionFailed = true;
        },
        addGlobalTagConditionSuccess: (state, action) => {
            const globalTag = state.items.find(item => item.id === action.payload.globalTagId)!;

            globalTag.conditions.push(action.payload);
            state.addGlobalTagCondition = false;
        },
        updateGlobalTagCondition: state => {
            state.updateGlobalTagCondition = true;
        },
        updateGlobalTagConditionFailed: state => {
            state.updateGlobalTagCondition = false;
            state.updateGlobalTagConditionFailed = true;
        },
        updateGlobalTagConditionSuccess: (state, action) => {
            const globalTag = state.items.find(item => item.id === action.payload.globalTagId)!;
            const globalTagConditionIndex = globalTag.conditions.findIndex(
                condition => condition.id === action.payload.id
            );

            globalTag.conditions[globalTagConditionIndex] = {
                ...state.items[globalTagConditionIndex],
                ...action.payload,
            };
            state.updateGlobalTagCondition = false;
        },
        deleteGlobalTagCondition: state => {
            state.deleteGlobalTagCondition = true;
        },
        deleteGlobalTagConditionFailed: state => {
            state.deleteGlobalTagCondition = false;
            state.deleteGlobalTagConditionFailed = true;
        },
        deleteGlobalTagConditionSuccess: (state, action) => {
            console.log(action.payload);
            const globalTag = state.items.find(item => item.id === action.payload.globalTagId)!;
            globalTag.conditions = globalTag.conditions.filter(
                item => item.id !== action.payload.id
            );

            state.deleteGlobalTagCondition = false;
        },
    },
});

const { actions, reducer } = globalTagsSlice;

export const {
    loadGlobalTags,
    loadGlobalTagsFailed,
    loadGlobalTagsSuccess,
    addGlobalTag,
    addGlobalTagFailed,
    addGlobalTagSuccess,
    deleteGlobalTag,
    deleteGlobalTagFailed,
    deleteGlobalTagSuccess,
    updateGlobalTag,
    updateGlobalTagSuccess,
    updateGlobalTagFailed,
    addGlobalTagCondition,
    addGlobalTagConditionFailed,
    addGlobalTagConditionSuccess,
    deleteGlobalTagCondition,
    deleteGlobalTagConditionFailed,
    deleteGlobalTagConditionSuccess,
    updateGlobalTagCondition,
    updateGlobalTagConditionFailed,
    updateGlobalTagConditionSuccess,
} = actions;

export default reducer;
