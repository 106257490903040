import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import pjson from '../package.json';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'rc-color-picker/assets/index.css';
import './css/index.scss';

import { Provider } from 'react-redux';
import { Suspense } from 'react';
import store from './store';
import axios from 'axios';

const App = React.lazy(() => import('./App'));

const root = document.getElementById('root');

axios.interceptors.request.use(function(config) {
    const token = store.getState().auth.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<></>}>
            <App />
        </Suspense>
    </Provider>,
    root
);

console.log(`%c KIOS Admin version: ${pjson.version} `, 'background-color: #ffdd2d; color: #333;');

reportWebVitals();
