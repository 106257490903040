import { createSlice } from '@reduxjs/toolkit';
import ResultsState from '../../types/ResultsState';

const initialState: ResultsState = {
    items: [],
    page: 1,
    count: 0,
    limit: 10,
    search: '',
    fetchResults: true,
    fetchResultsFailed: false,
    fetchDischarge: false,
    fetchDischargeFailed: false,
    deleteResult: false,
    deleteResultFailed: false,
};

const resultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {
        loadResults: state => {
            state.fetchResults = true;
        },
        loadResultsFailed: state => {
            state.fetchResults = false;
            state.fetchResultsFailed = true;
        },
        loadResultsSuccess: (state, action) => {
            state.items = action.payload.data;
            state.count = action.payload.count;
            state.fetchResults = false;
        },
        loadDischarge: state => {
            state.fetchDischarge = true;
        },
        loadDischargeFailed: state => {
            state.fetchDischarge = false;
            state.fetchDischargeFailed = true;
        },
        loadDischargeSuccess: state => {
            state.fetchDischarge = false;
        },
        deleteResult: state => {
            state.deleteResult = true;
        },
        deleteResultFailed: state => {
            state.deleteResult = false;
            state.deleteResultFailed = true;
        },
        deleteResultSuccess: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload.id);
            state.deleteResult = false;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload;
        },
    },
});

const { actions, reducer } = resultsSlice;

export const {
    loadResults,
    loadResultsFailed,
    loadResultsSuccess,
    loadDischarge,
    loadDischargeFailed,
    loadDischargeSuccess,
    deleteResult,
    deleteResultFailed,
    deleteResultSuccess,
    setSearch,
    setPage,
    setLimit,
} = actions;

export default reducer;
