import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import activitiesReducer from './activities/activitiesReducer';
import authReducer from './auth/authReducer';
import usersReducer from './users/usersReducer';
import scalesReducer from './scales/scalesReducer';
import scalesGroupsReducer from './scalesGroup/scalesGroupsReducer';
import resultsReducer from './results/resultsReducer';
import menuReducer from './menu/menuReducer';
import tagsReducer from './tags/tagsReducer';
import globalTagsReducer from './globalTags/globalTagsReducer';

const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
});

const store = configureStore({
    reducer: {
        activities: activitiesReducer,
        users: usersReducer,
        auth: authReducer,
        scales: scalesReducer,
        scalesGroups: scalesGroupsReducer,
        results: resultsReducer,
        menu: menuReducer,
        tags: tagsReducer,
        globalTags: globalTagsReducer,
    },
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
