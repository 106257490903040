import { createSlice } from '@reduxjs/toolkit';
import ScalesState from '../../types/ScalesState';

const initialState: ScalesState = {
    items: [],
    fetchScales: true,
    fetchScalesFailed: false,
    deleteScale: false,
    deleteScaleFailed: false,
    addScale: false,
    addScaleFailed: false,
    updateScale: false,
    updateScaleFailed: false,
};

const scalesSlice = createSlice({
    name: 'scales',
    initialState,
    reducers: {
        loadScales: state => {
            state.fetchScales = true;
        },
        loadScalesFailed: state => {
            state.fetchScales = false;
            state.fetchScalesFailed = true;
        },
        loadScalesSuccess: (state, action) => {
            state.items = action.payload;
            state.fetchScales = false;
        },
        deleteScale: state => {
            state.deleteScale = true;
        },
        deleteScaleFailed: state => {
            state.deleteScale = false;
            state.deleteScaleFailed = true;
        },
        deleteScaleSuccess: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload);
            state.deleteScale = false;
        },
        addScale: state => {
            state.addScale = true;
        },
        addScaleFailed: state => {
            state.addScale = false;
            state.addScaleFailed = true;
        },
        addScaleSuccess: (state, action) => {
            state.items.push(action.payload);
            state.addScale = false;
        },
        updateScale: state => {
            state.updateScale = true;
        },
        updateScaleFailed: state => {
            state.updateScale = false;
            state.updateScaleFailed = true;
        },
        updateScaleSuccess: (state, action) => {
            let userIndex = state.items.findIndex(item => item.id === action.payload.id)!;

            state.items[userIndex] = {
                ...state.items[userIndex],
                ...action.payload,
            };
            state.updateScale = false;
        },
    },
});

const { actions, reducer } = scalesSlice;

export const {
    loadScales,
    loadScalesFailed,
    loadScalesSuccess,
    deleteScale,
    deleteScaleFailed,
    deleteScaleSuccess,
    addScale,
    addScaleFailed,
    addScaleSuccess,
    updateScale,
    updateScaleFailed,
    updateScaleSuccess,
} = actions;

export default reducer;
