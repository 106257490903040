import { createSlice } from '@reduxjs/toolkit';
import ScalesGroupsState from '../../types/ScalesGroupState';

const initialState: ScalesGroupsState = {
    items: [],
    fetchScalesGroups: true,
    fetchScalesGroupsFailed: false,
    deleteScale: false,
    deleteScaleFailed: false,
    addScale: false,
    addScaleFailed: false,
    updateScale: false,
    updateScaleFailed: false,
};

const scalesGroupsSlice = createSlice({
    name: 'scalesGroup',
    initialState,
    reducers: {
        loadScalesGroups: state => {
            state.fetchScalesGroups = true;
        },
        loadScalesGroupsFailed: state => {
            state.fetchScalesGroups = false;
            state.fetchScalesGroupsFailed = true;
        },
        loadScalesGroupsSuccess: (state, action) => {
            state.items = action.payload;
            state.fetchScalesGroups = false;
        },
        deleteScalesGroup: state => {
            state.deleteScale = true;
        },
        deleteScalesGroupFailed: state => {
            state.deleteScale = false;
            state.deleteScaleFailed = true;
        },
        deleteScalesGroupSuccess: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload.id);
            state.deleteScale = false;
        },
        addScalesGroup: state => {
            state.addScale = true;
        },
        addScalesGroupFailed: state => {
            state.addScale = false;
            state.addScaleFailed = true;
        },
        addScalesGroupSuccess: (state, action) => {
            state.items.push(action.payload);
            state.addScale = false;
        },
        updateScalesGroup: state => {
            state.updateScale = true;
        },
        updateScalesGroupFailed: state => {
            state.updateScale = false;
            state.updateScaleFailed = true;
        },
        updateScalesGroupSuccess: (state, action) => {
            let userIndex = state.items.findIndex(item => item.id === action.payload.id)!;

            state.items[userIndex] = {
                ...state.items[userIndex],
                ...action.payload,
            };
            state.updateScale = false;
        },
    },
});

const { actions, reducer } = scalesGroupsSlice;

export const {
    loadScalesGroups,
    loadScalesGroupsFailed,
    loadScalesGroupsSuccess,
    deleteScalesGroup,
    deleteScalesGroupFailed,
    deleteScalesGroupSuccess,
    addScalesGroup,
    addScalesGroupFailed,
    addScalesGroupSuccess,
    updateScalesGroup,
    updateScalesGroupFailed,
    updateScalesGroupSuccess,
} = actions;

export default reducer;
