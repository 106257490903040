import { createSlice } from '@reduxjs/toolkit';
import UsersState from '../../types/UsersState';

const initialState: UsersState = {
    items: [],
    fetchUsers: false,
    fetchUsersFailed: false,
    deleteUser: false,
    deleteUserFailed: false,
    addUser: false,
    addUserFailed: false,
    updateUser: false,
    updateUserFailed: false,
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        loadUsers: state => {
            state.fetchUsers = true;
        },
        loadUsersFailed: state => {
            state.fetchUsers = false;
            state.fetchUsersFailed = true;
        },
        loadUsersSuccess: (state, action) => {
            state.items = action.payload;
            state.fetchUsers = false;
        },
        deleteUser: state => {
            state.deleteUser = true;
        },
        deleteUserFailed: state => {
            state.deleteUser = false;
            state.deleteUserFailed = true;
        },
        deleteUserSuccess: (state, action) => {
            state.items = state.items.filter(item => item.leaderId !== action.payload.leaderId);
            state.deleteUser = false;
        },
        addUser: state => {
            state.addUser = true;
        },
        addUserFailed: state => {
            state.addUser = false;
            state.addUserFailed = true;
        },
        addUserSuccess: (state, action) => {
            state.items.push(action.payload);
            state.addUser = false;
        },
        updateUser: state => {
            state.updateUser = true;
        },
        updateUserFailed: state => {
            state.updateUser = false;
            state.updateUserFailed = true;
        },
        updateUserSuccess: (state, action) => {
            let userIndex = state.items.findIndex(
                item => item.leaderId === action.payload.leaderId
            )!;

            state.items[userIndex] = {
                ...state.items[userIndex],
                ...action.payload,
            };
            state.updateUser = false;
        },
    },
});

const { actions, reducer } = usersSlice;

export const {
    loadUsers,
    loadUsersFailed,
    loadUsersSuccess,
    deleteUser,
    deleteUserFailed,
    deleteUserSuccess,
    addUser,
    addUserFailed,
    addUserSuccess,
    updateUser,
    updateUserFailed,
    updateUserSuccess,
} = actions;

export default reducer;
