import { createSlice } from '@reduxjs/toolkit';
import TagsState from '../../types/TagsState';

const initialState: TagsState = {
    items: [],
    fetchTags: true,
    fetchTagsFailed: false,
    fetchReloadTags: false,
    fetchReloadTagsFailed: false,
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        loadTags: state => {
            state.fetchTags = true;
        },
        loadTagsFailed: state => {
            state.fetchTags = false;
            state.fetchTagsFailed = true;
        },
        loadTagsSuccess: (state, action) => {
            state.items = action.payload;
            state.fetchTags = false;
        },
        reloadTags: state => {
            state.fetchReloadTags = true;
        },
        reloadTagsFailed: state => {
            state.fetchReloadTags = false;
            state.fetchReloadTagsFailed = true;
        },
        reloadTagsSuccess: (state, action) => {
            state.items = action.payload;
            state.fetchReloadTags = false;
        },
    },
});

const { actions, reducer } = tagsSlice;

export const {
    loadTags,
    loadTagsFailed,
    loadTagsSuccess,
    reloadTags,
    reloadTagsFailed,
    reloadTagsSuccess,
} = actions;

export default reducer;
