import { createSlice } from '@reduxjs/toolkit';
import MenuState from '../../types/MenuState';

const initialState: MenuState = {
    isOpened: false,
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setIsOpened: (state, action) => {
            state.isOpened = action.payload;
        },
    },
});

const { actions, reducer } = menuSlice;

export const { setIsOpened } = actions;

export default reducer;
