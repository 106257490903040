import { createSlice } from '@reduxjs/toolkit';
import AuthState from '../../types/AuthState';

const initialState: AuthState = {
    token: localStorage.getItem('sso_token_leader_id'),
    user: {
        unti_id: null,
        username: '',
        email: '',
        lastname: '',
        firstname: '',
        secondname: '',
        leader_id: '',
        tags: [],
        role: null,
    },
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logIn: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logOut: state => {
            state.isLoggedIn = false;
            state.token = null;
        },
    },
});

const { actions, reducer } = authSlice;

export const { logIn, logOut } = actions;

export default reducer;
